















































import { Component, Prop, Vue } from "vue-property-decorator";
import colors from "@/styles/colors";
import { ButtonSizes } from "@/components/Button.vue";
import Button from "@/components/Button.vue";
import CarouselSlide, { SlideObject } from "@/components/CarouselSlide.vue";

@Component({
  name: "Carousel",
  components: {
    Button,
    CarouselSlide,
  },
})
export default class Carousel extends Vue {
    @Prop() slides!: SlideObject[];
    @Prop() autoLoop!: boolean;

    private buttonSize: ButtonSizes = ButtonSizes.medium;
    private buttonLeftArrow: string = "ᐸ";
    private buttonRightArrow: string = "ᐳ";
    private buttonColor: string = colors.get().input;

    private carouselRotationIndex: number = 0;

    private timer!: NodeJS.Timeout;

    private rotate(direction: string): void {
        if (this.slides.length - 1 > 0) {
            let tempIndex = this.carouselRotationIndex;
            this.carouselRotationIndex = -1;

            clearInterval(this.timer);

            setTimeout(() => {
                if (direction === "left") {
                    if (tempIndex === 0) this.carouselRotationIndex = this.slides.length - 1;
                    else this.carouselRotationIndex = tempIndex - 1;
                } else if (direction === "right") {
                    if (tempIndex === this.slides.length - 1) this.carouselRotationIndex = 0;
                    else this.carouselRotationIndex = tempIndex + 1;
                }
            }, 150);

            this.timer = setInterval(() => {
                this.rotate("right");
            }, 5000);
        }
    }

    created() {
        if (this.autoLoop && (this.slides.length - 1) > 0) {
            this.timer = setInterval(() => { this.rotate("right"); }, 5000);
        }
    }
}
