






import { Component, Prop, Vue } from "vue-property-decorator";
import { ButtonSizes } from "@/components/Button.vue";

@Component({
  name: "TextArea",
})
export default class TextArea extends Vue {
  @Prop() text!: string;
}
