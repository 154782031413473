















import { Component, Prop, Vue } from "vue-property-decorator";
import TextArea from "@/components/TextArea.vue";
import Picture, { ImageSize } from "@/components/Picture.vue";

@Component({
  name: "BottomBar",
  components: {
    TextArea,
    Picture,
  },
})
export default class BottomBar extends Vue {
  private logoSize: ImageSize = ImageSize.logo;
}
