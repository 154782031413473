






import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "Subtitle",
})
export default class Subtitle extends Vue {
  @Prop() subtitle!: string;
}
