








import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "NavBarSingleOption",
})
export default class NavBarSingleOption extends Vue {
  @Prop() private path!: string;
  @Prop() private text!: string;
  @Prop() private listId!: number;

  private dropdownToggle(): void {
    this.$emit("dropdown", this.listId);
  }
}
