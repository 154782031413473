





















import { Component, Prop, Vue } from "vue-property-decorator";
import NavBarSingleOption from "@/components/NavBarSingleOption.vue";

export interface NavBarDropdownObject {
  path: string;
  text: string;
}

@Component({
  name: "NavBarDropdown",
  components: {
    NavBarSingleOption,
  },
})
export default class NavBarDropdown extends Vue {
  private opacity: number = 0;
  @Prop() private routes!: NavBarDropdownObject[];
  @Prop() private text!: string;
  @Prop() private listId!: number;
  @Prop() private dropdown!: number;

  private dropdownToggle(): void {
    this.dropdown === this.listId
      ? this.$emit("dropdown", -1)
      : this.$emit("dropdown", this.listId);
  }
}
