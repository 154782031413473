































import { Component, Prop, Vue } from "vue-property-decorator";
import colors from "@/styles/colors";
import TextArea from "@/components/TextArea.vue";
import { ImageObject, ImageSize } from "./Picture.vue";
import Picture from "@/components/Picture.vue";
import Button, { ButtonSizes } from "@/components/Button.vue";

export interface SlideObject {
  title: string;
  description: string;
  image: ImageObject;
  link?: string;
}

@Component({
  name: "CarouselSlide",
  components: {
    TextArea,
    Picture,
    Button,
  },
})
export default class CarouselSlide extends Vue {
  @Prop() description!: string;
  @Prop() title!: string;
  @Prop() image!: ImageObject;
  @Prop() carouselRotation?: number;
  @Prop() index!: number;
  @Prop() link!: string;
  private imgSize: ImageSize = ImageSize.medium;
  private textWidth: string = "30%";

  private buttonColor: string = colors.get().accent;
  private buttonSize: ButtonSizes = ButtonSizes.medium;

  private goToLink(value: string): void {
    window.open(value);
  }
}
