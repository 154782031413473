











import { Component, Prop, Vue } from "vue-property-decorator";

export enum ButtonSizes {
  small = "0.2rem 0.5rem 0.2rem 0.5rem",
  medium = "0.5rem 1rem 0.5rem 1rem",
  big = "1rem 1.6rem 1rem 1.6rem",
}

@Component({
  name: "PageCover",
})
export default class PageCover extends Vue {
  @Prop() color!: string;
  @Prop() size!: ButtonSizes;
  @Prop() text!: string;
  @Prop() clickParams?: string;
  @Prop() hoverable?: "hoverable";

  private handleClick(): void {
    this.$emit("handleClick", this.clickParams);
  }
}
