






import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "Divider",
})
export default class NavBarDropdown extends Vue {}
