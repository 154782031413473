













































import { Component, Prop, Vue } from "vue-property-decorator";
import colors from "@/styles/colors";
import Button, { ButtonSizes } from "@/components/Button.vue";

export enum FileInputTypes {
  image = '.jpg,.png',
  document = '.pdf,.docx,.doc,.xlsx,.xls,.csv,.jpg,.png',
}

export interface InputObject {
  placeholder: string;
  value: string | File;
  inputType:
    | "button"
    | "checkbox"
    | "color"
    | "date"
    | "datetime-local"
    | "email"
    | "file"
    | "hidden"
    | "image"
    | "month"
    | "number"
    | "password"
    | "radio"
    | "range"
    | "reset"
    | "search"
    | "submit"
    | "tel"
    | "text"
    | "textarea"
    | "time"
    | "url"
    | "week";
  height?: "20rem";
  name: string;
  fileInputType?: FileInputTypes;
}

@Component({
  name: "FormInput",
  components: {
    Button,
  },
})
export default class FormInput extends Vue {
  @Prop() value!: string;
  @Prop() height?: string;
  @Prop() placeholder!: string;
  @Prop() inputType!:
    | "button"
    | "checkbox"
    | "color"
    | "date"
    | "datetime-local"
    | "email"
    | "file"
    | "hidden"
    | "image"
    | "month"
    | "number"
    | "password"
    | "radio"
    | "range"
    | "reset"
    | "search"
    | "submit"
    | "tel"
    | "text"
    | "time"
    | "url"
    | "week";
  @Prop() index!: number;
  @Prop() fileInputType?: FileInputTypes;

  private fileButtonColor: string = colors.get().input;
  private fileButtonSize: ButtonSizes = ButtonSizes.medium;
  private fileName: string | null = null;

  get inputValue() {
    return this.value;
  }

  set inputValue(value: string) {
    this.$emit("changeValue", value, this.index);
  }

  created() {
    this.inputValue = this.value;
  }

  private selectFile(): void {
    document.getElementById("file-input")?.click();
  }

  private onFileChanged(event: any): void {
    const files: FileList = event.target.files;
    let result: File = <File>files.item(0);
    this.fileName = result.name;
    this.$emit("changeValue", result, this.index);
  }
}
