
































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import NavBarSingleOption from "@/components/NavBarSingleOption.vue";
import NavBarDropdown, {
  NavBarDropdownObject,
} from "@/components/NavBarDropdown.vue";
import pathNames from "@/router/pathNames";
import colors from "@/styles/colors";
import Picture, { ImageSize } from "@/components/Picture.vue";
import Button, { ButtonSizes } from "@/components/Button.vue";

export interface NavBarRouteObject {
  name: string;
  path?: string;
  paths?: NavBarDropdownObject[];
}

@Component({
  name: "NavBar",
  components: {
    NavBarSingleOption,
    NavBarDropdown,
    Picture,
    Button,
  },
})
export default class NavBar extends Vue {
  private logoSize: ImageSize = ImageSize.logo;
  private menuExpand: boolean = false;
  private screenSize: number = window.innerWidth;

  private mobileMenuButtonColor: string = colors.get().main;
  private mobileMenuButtonSize: ButtonSizes = ButtonSizes.big;

  @Prop() private navBarRoutes!: NavBarRouteObject[];

  destroyed() {
    window.removeEventListener("resize", this.screenResize);
  }

  private screenResize(e: UIEvent) {
    console.log(window.innerWidth);
    this.screenSize = window.innerWidth;
  }

  private toggleDisplayMode(): void {
    const el = document.documentElement;
    switch (document.documentElement.getAttribute("data-theme")) {
      //using a switch here in case we ever for some reason (yes I will judge you) want to add more themes
      case "dark":
        colors.setTheme("light", el);
        break;
      case "light":
        colors.setTheme("dark", el);
        break;
      default:
        colors.setTheme("dark", el);
        break;
    }
    window.location.reload();
  }

  private isDarkMode(): boolean {
    return localStorage.getItem("theme") == "dark";
  }

  private colorToggle: string = colors.get().background;

  private scrollListener(event: Event): void {
    if (window.scrollY > 0) {
      this.colorToggle = colors.get().main;
    } else {
      this.colorToggle = colors.get().background;
    }
  }

  created() {
    window.addEventListener("scroll", this.scrollListener);
    // responsive design: check window size
    window.addEventListener("resize", this.screenResize);
  }

  private dropdown: number = -1;



  private toggleDropdown(listId: number): void {
    this.dropdown = listId;
  }
}
