

















































import { Component, Prop, Vue } from "vue-property-decorator";
import Picture, { ImageObject, ImageSize } from "@/components/Picture.vue";
import TextArea from "@/components/TextArea.vue";
import Button, { ButtonSizes } from "@/components/Button.vue";
import colors from "@/styles/colors";

export interface CalendarItem {
  title: string;
  description: string;
  date: string;
  link: string;
  image: ImageObject;
}

@Component({
  name: "Calendar",
  components: {
    Picture,
    TextArea,
    Button,
  },
})
export default class Calendar extends Vue {
    @Prop() calendar!: CalendarItem[];
    @Prop() totalCount!: number;
    @Prop() limit?: boolean;

    private buttonSize: ButtonSizes = ButtonSizes.medium;
    private buttonColor: string = colors.get().accent;
    private collapseButtonColor: string = "#FA8072";
    private calendarImageSize: ImageSize = ImageSize.auto;

    private paginationIndex: number = 0;

    private goToLink(value: string) {
        window.open(value);
    }

    private showMoreResults() {
        this.paginationIndex++;
        this.$emit("showMore", this.paginationIndex);
    }

    private collapseResults() {
        this.paginationIndex = 0;
        this.$emit("collapseResults");
    }
}
