









import { Component, Prop, Vue } from "vue-property-decorator";
import TextArea from "@/components/TextArea.vue";

@Component({
  name: "PageCover",
  components: {
    TextArea,
  },
})
export default class PageCover extends Vue {
  @Prop() private title!: string;
  @Prop() private subtitle?: string;
}
