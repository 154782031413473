



















import { Component, Prop, Vue } from "vue-property-decorator";
import FormInput, { InputObject } from "@/components/FormInput.vue";

@Component({
  name: "Form",
  components: {
    FormInput,
  },
})
export default class Form extends Vue {
  @Prop() value!: InputObject[];

  private onValueChange(inputValue: string | File, index: number): void {
    this.value[index].value = inputValue;
  }

  private handleSubmit(): void {
    this.$emit("handleFormSubmit", this.value);
  }
}
